import React from 'react'
import { Link } from 'react-router-dom';

function Kartinginfo() {
  return (
    <section id="kartinginfo" class="bg-kartinginfo px-3">
        <div class="row g-0 kartinginfo-text">
            <div class="col-12 text-center kartinginfo-title">
              <h2>I Want To Kart.  What Are My Options?</h2>
            </div>
            <div class="col-12 col-xl-8 offset-xl-2">
              <p>I have done a lot of research into the various routes into motorsports.  I love karting and it's the most obvious place to start.  I am 
                sharing this here to give you a rough idea of the costs and level of commitment involved.  This is intended as a guide only and any information should be checked 
                before committing to a kart/series.
              </p>
              <br></br>
              <p>For many, the cost of just one season in a competitive karting series will be prohibitive.  Not only is karting a huge financial commitment for most families, it 
                also takes up an enormous amount of time and involves significant amounts of travel.  For many karting enthusiasts, like myself, who have siblings and working parents, the time 
                and travel commitments can be just as prohibitive as the costs involved.  That being said, I am keen to find a way around this!</p>
            </div>

            <div class="col-12 col-xl-4 offset-xl-2 py-5">
              <h4><strong>Indoor Karting</strong></h4>
              <br></br>
              <p>Indoor karting is a great place to start.  It is similarly priced to outdoor arrive and drive sessions but with shorter tracks and slower karts, 
                indoor karting creates the ideal environment for complete beginners.  Indoor tracks are also independent of the weather and, let's face it, in the UK 
                this is often a major factor in whether or not we leave the house!  All the necessary equipment is supplied - you just need sensible clothing/footwear and to attend the safety briefing and you're good to go!</p>
              <p>I started out at my local <Link to="https://www.team-sport.co.uk/" target="_blank" rel="noopener noreferrer">TeamSport</Link>.  The TeamSport academy gives you a solid introduction to the skills you need for karting, like learning the track, finding the driving 
                line, battling, defending etc.  I started with the weekend academy and then moved on to the more competitive evening socials and BIKC.  BIKC (British Indoor Karting Championship) is so much fun and a good 
                entry point into more formal competitive karting.</p>
              <p>Indoor karting is fun and a great place to learn the basics but if you want to go further, faster and up into open-wheelers you'll need to get outside!
                </p>
            </div>
            <div class="col-12 col-xl-4 mb-3 py-5 d-flex justify-content-end align-items-center">
            <img
                    className="teamsport-image"
                    width="560"
                    height="315"
                    alt="Karts parked up at TeamSport, Tower Bridge"
                    src={require("../assets/images/teamsport.jpg")}
                /> 
            </div>
            <div class="col-12 col-xl-8 offset-xl-2 py-5">
              <h4><strong>Arrive and Drive Series</strong></h4>
              <br></br>
              <p>Arrive and drive karting is similary priced to indoor karting but can vary from track to track.  All the necessary equipment like helmets, race suits etc are provided although as you progress you may wish to invest 
                in your own.  Things like maintenance, tyres and fuel are all taken care of too so, as the name suggests, you just turn up and drive!</p>
              <p>Competitive arrive and drive series are another great way to test out competitive karting.  With all competitors driving the same machinery, the emphasis is on driver ability and racecraft rather than the hardware.</p>
              <br></br>
              <h5><strong>Daniel Ricciardo League</strong></h5>
              <p>The <Link to="https://danielricciardoseries.com/" target="_blank" rel="noopener noreferrer">Daniel Ricciardo arrive n drive series</Link> is for 8-13 year olds.  It looks like a good way to test out the karts/series if you are looking 
              to commit to the Daniel Ricciardo owner driver league.</p>
              <br></br>
              <h5><strong>Global Karting League (GKL)</strong></h5>
              <p><Link to="https://www.globalkartingleague.com/" target="_blank" rel="noopener noreferrer">Global Karting League</Link> (previously known as Total Karting Zero) describe themselves as being an affordable pathway to the top of motorsport 
              for drivers aged 6-17.  These are electric karts and a lot of fun!  I have done a couple of practice sessions and one race day with GKL and really enjoyed it.</p>
              <p>There are two championships: the rookie championship and the national championship.  Drivers are expected to complete at least 3 rookie races before they are ready to move up to the national level but for complete beginners it is likely to be more.  
                Each championship has 9 rounds throughout the year.</p>
              <ul>
                <li class="pb-2">There are practice weekends at the beginning of the year and 2 practice sessions on the Saturday of every race weekend.  These cost £165-£195 depending on the driver age and weight category.</li>
                <li class="pb-2">Each of the 9 race sessions cost £265-£295 depending on the driver age and weight category.</li>
              </ul>
              <p>The total price for all 9 rounds of the rookie championship and both Saturday practice sessions each race weekend would <strong>cost £6165</strong>.  Remember, this will include helmet and suit rental but it will not include travel and accommodation.</p>
              <br></br>
              <h5><strong>Club 100</strong></h5>
              <p><Link to="https://club100.co.uk/" target="_blank" rel="noopener noreferrer">Club 100</Link> is a competitive arrive and drive league that's seen a young Lewis Hamilton and Super GT compete.   The championship is made up of 10 race weekends.
              Club 100 recommends you do some karting at your local track to learn the basics before you join, so it's not suitable for a complete beginner.  You are then required to attend academy sessions until you are at the required level for the championship.</p>
              <ul>
                <li class="pb-2">There's a one off £55 registration fee.</li>
                <li class="pb-2">Each academy session costs £180.</li>
                <li class="pb-2">Open practice sessions cost £224 each.</li>
                <li class="pb-2">Each of the 10 race rounds costs £262.</li>
              </ul>
              <p>So, for 2 academy sessions, all 16 open practice sessions and all 10 rounds of the championship, that will <strong>cost £6619</strong> for a season.</p>
            </div>
            <div class="col-12 col-xl-8 offset-xl-2 py-5">
              <h4><strong>Owner Driver Series</strong></h4>
              <br></br>
              <p>Owning your own kart is an expensive hobby.  There are many different makes and models to choose from and each comes with the opportunity to upgrade various parts of the kart.  For some, this leads to incredibly valuable, highly competitive  
                karts and for others it requires self-discipline and a strict handle on the budget!</p>
              <p>I'm going to focus on owner driver championships as a more direct comparison with arrive and drive.  These championships can be phenomenally expensive, especially as you climb the karting ranks. Even more budget-friendly options 
                require you to purchase your own kart and all the equipment and tyres for the season, as well as maintenance costs, travel and accommodation.  The advantage though is that you only drive your own kart so you can make the setup suitable for your driving style 
                within the parameters of the series. You can also take it to tracks for more practice outside of the series schedule.  One major disadvantage though is that drivers with more financial backing are able to put more money into different types of chassis, engines 
                and upgrades so they stand more chance of being competitive.</p>
              <br></br>
              <h5><strong>Daniel Ricciardo Series</strong></h5>
              <p>F1 driver Daniel Riccardo has his own karting league: <Link to="https://danielricciardoseries.com/" target="_blank" rel="noopener noreferrer">Daniel Ricciardo Series</Link>.  In order to compete in it you have to attend some academy sessions to pass the basic 
              driving test and buy the kart.  This series aims to be a more affordable owner driver championship and there are limits on what can be changed within the kart, evening the playing field somewhat compared to other series.  There are 8 rounds per season.</p>
              <ul>
                <li class="pb-2">The kart is about £6000 (inc VAT).</li>
                <li class="pb-2">There's a £295 season registration fee.</li>
                <li class="pb-2">The price for race entries, sprocket, chain, oil and tyres for the season is another £4224.</li>
                <li class="pb-2">You'll need a kart trolley, battery charger, transponder, steering wedge and the screen for the steering wheel that would be another £1345.</li>
                <li class="pb-2">Let's assume 3 of the 8 race weekends are wet.  That will be another £1123 for wet tyres.</li>
                <li class="pb-2">The engine needs to be serviced after every 8 hours of use.  Let's say I do 8 hours of karting every 2 weeks. I would need to alternate between a full rebuild and a top end rebuild so for 1 season, it would cost another £8820.</li>
                <li class="pb-2">Testing is about £90 per session.</li>
                <li class="pb-2">An owner karting day at the track before every race as a practice session will add another £720.</li>
              </ul>
              <p>In total, one year of the Daniel Ricciardo owner driver series would <strong>cost £22,525</strong>.</p>
              <br></br>
              <h5><strong>Local Championships</strong></h5>
              <p>Tracks and Kart clubs across the UK host their own championships.  Cheaper series will be in the same region as the Daniel Ricciardo Series but depending on how competitive you want to be and the upgrades being brought by other competiors, it could 
                add up to much more.</p>
              <p>Beyond this, there are national and European championships where the costs involved become astronomical.   You will need to be part of a team, introducing new opportunities to learn and challenges to overcome.</p>
            </div>
            <div class="col-12 col-xl-4 offset-xl-2 py-5">
              <h4><strong>Sim Racing</strong></h4>
              <br></br>
              <p>For many people, karting in these championships year on year is not an option.  I am no exception.  So I have been looking for another way.  I believe sim racing offers an opportunity to build skills and racecraft in a much more affordable way and I am putting 
                this to the test.</p>
              <p>Sim racing isn't cheap and budgets can vary hugely but I believe it can be a cheaper yet still effective way to get up to speed and open up opportunities.</p>
              <p>If you are interested in a more detailed breakdown and to see how I plan to do this, check out my video<a href="https://www.youtube.com/watch?v=cGPJJppDCCw>/p?" target="_blank" rel="noopener noreferrer"> here</a>.  Don't forget to subscribe to my channel to receive updates and be notified when the next episodes are released.</p>
            </div>
            <div class="col-12 col-xl-4 mb-3 py-5 d-flex justify-content-end align-items-center">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/fTEG_EMeErk?si=5XfXXAq9Zg1JGJaq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </section>
  )
}

export default Kartinginfo