import React from 'react'
import { Link } from 'react-router-dom';

function Seriesinfo() {
  return (
    <section id="series" class="bg-series px-3">
        <div class="row g-0 series-text">
            <div class="col-12 text-center series-title">
              <h2>Is Karting the Only Way into Open-wheel Racing?</h2>
            </div>
            <div class="col-12 col-xl-8 offset-xl-2">
              <h4><strong>I want to be an F1 driver!</strong></h4>
              <br></br>
              <p>I love racing.  I breathe sim racing and compete in a league!  I go karting at my local TeamSport as often as I can and recently I have tried some sessions 
                with Global Karting League (GKL). So far it’s been recreational but I would love to participate in a full season at some point and see where I can 
                place in a championship.  I’ve been looking at <Link to="/Karting">my options</Link> and the prices can be eye watering.  Not only that, 
                the travel and time commitments can be crippling to families with more than one child, large workloads or other commitments.
                </p>
              <br></br>
              <p>This year I haven’t had many opportunities to get to the track. I had 3 sessions with GKL between January and April, totalling around an hour 
                  and a half of track time but other than this my track time this year has been primarily on the sim.  Towards the end of April, I managed to visit 
                  my local Teamsport for the first time this year and I was surprised to discover that I had improved!  I am very familiar with this circuit as I’ve 
                  been going there on and off for a few years now so I expected that I would be back up to speed after a warm up session or two. However, from the first 
                  session I was on it!  My pace and consistency were there immediately and were getting better with each session.  My personal times alone don't provide 
                  the full picture though.  I was able to benchmark myself against people who I race against regularly too and there was, without a doubt, a marked 
                  improvement in my times compared to theirs.  For almost 5 months I have driven, almost exclusively, on the simulator, using titles like Asseto Corsa, 
                  rFactor 2 and F1 23.
                </p>
                <br></br>
                <p>This got me thinking...</p>
            </div>
            <div class="col-12 text-center mb-3 py-5">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/cGPJJppDCCw?si=RVLTjKRe2Ql79GtT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <br></br>
            <p>Check out the video <a href="https://www.youtube.com/watch?v=cGPJJppDCCw>/p?" target="_blank" rel="noopener noreferrer"> here</a>.</p>
            </div>
            <div class="col-12 col-xl-8 offset-xl-2 py-5">
              <h4><strong>How possible would it be to train effectively for a karting season primarily on a simulator?</strong></h4>
              <br></br>
              <p>Compared to owner-driver and arrive and drive leagues, I think this could be a much more accessible way to get into karting.  I'm going to put it to the test.  I want 
                to see how much sim training at the beginning of my karting journey can prepare me for competitive karting.</p>
              <br></br>
              <p>There are a number of karting tracks in the UK that are available on simulators.  Initially I will be using Assetto Corsa and the KartSim DLC on rFactor.  As I get further into the series I will also look at some of the other titles available.  The first episode of 
                the series explains in more details how I plan to do this.
              </p>
              <br></br>
              <p>Karting can be pretty difficult to simulate because most of the feedback comes through the seat. Handling and speed are also heavily influenced by other factors like the height and weight of the driver, weight shifting during corners and even wind speeds.  No 
                simulation is perfect but I will explore ways to make a relatively affordable yet immersive simulator at home.  I’ll be looking at solutions such as: haptic feedback options, comparing VR to single and triple monitor setups and, if I can, try out a motion rig to 
                see how much better that may be in replicating the real world experience of karting.</p>
                <br></br>
                <p>Subscribe to my channel to receive updates and be notified when the next episode is released.</p>
            </div>
        </div>
    </section>
  )
}

export default Seriesinfo