import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { TrackTitan } from "./pages/TrackTitan";
import { Contact } from "./pages/Contact";
import { Series } from "./pages/Series";
import { Karting } from "./pages/Karting";
import './App.css';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  console.log("Deployed");
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/TrackTitan" element={<TrackTitan />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Series" element={<Series />} />
        <Route path="/Karting" element={<Karting />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App