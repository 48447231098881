import React from 'react'

function Hero() {
  return (
    <section id="hero" class="bg-hero d-none d-md-block">
        <div class="row g-0">
            <div class="col">
                <img
                    className="hero-pic"
                    alt="racing master 45 on track"
                    src={require("../assets/images/hero.jpg")}
                /> 
            </div>
        </div>
        <div class="row g-0 py-4">
            <div class="col text-center hero-text">
                <h2>Welcome to my sim racing and karting journey!</h2>
            </div>
        </div>
        <div className="row g-0 banner">
      </div>
    </section>
  )
}

export default Hero