import React from 'react'
import Youtubebutton from './Youtubebutton'
import { Link } from 'react-router-dom';


function Projects() {
  return (
    <section id="projects" className = "pb-5 bg-projects">

      <div className="row g-0 sim-racing">
        <div className = "col-10 col-xl-4 sim-text px-4 mb-0">
          <h3><strong>Sim Racing</strong></h3>
          <p class="py-4">I've been sim racing since 2020 and race on: F1 23, AMS 2, iRacing, Project Cars 2, Kartcraft, rFactor2 and Assetto Corsa.  I race weekly in a competitive F1 23 league and am learning so much.</p>
          <p class="pb-4">I love using Track Titan!  It has helped me to massively improved my sim racing lap times in just a few weeks.  Check it out <Link to="/TrackTitan" >here</Link></p>
          <Youtubebutton />
        </div>
      </div>

      <div className="row g-0 karting">
        <div className = "col-xl-4 d-none d-xl-block">
          <img
            className="editing-pic"
            alt="racing master 45 thumbnail"
            src={require("../assets/images/karting.jpg")}
          /> 
        </div>
        <div className = "col-xl-4 col-10 karting-text px-4 mb-0">
          <h3><strong>Karting</strong></h3>
          <p class="py-4">I've been indoor karting at Teamsport since 2021 and this year I reached the local finals of the BIKC 2023. I also love outdoor karting and rent karts at my local track as well as competing in events around the UK. I am trying to get my own kart soon!</p>
        </div>
      </div>

      <div className="row g-0 editing">
        <div className = "col-10 col-xl-4 editing-text px-4 mb-0">
          <h3><strong>Video Editing</strong></h3>
          <p class="py-4">I edit all my own videos using Adobe Premier Pro and After Effects.</p>
          <Youtubebutton />
        </div>
      </div>

    </section>
  )
}

export default Projects