import React from 'react'
import Buymeacoffeebutton from './Buymeacoffeebutton'
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <section id="navbar">
      <nav class="navbar fixed-top navbar-expand-md bg-light">
      <div class="container-fluid">
        <Link class="navbar-brand text-danger p-2 nav-logo-font d-none d-md-inline-block" to="/ ">
        <span><img src={require("../assets/images/logos.jpg")} alt="RM45 logo" width="50" height="50" /></span><strong> Racing Master 45</strong>
        </Link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <Link class="nav-link" to="/" reloadDocument>Home</Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/Series">
                Current Series
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/Karting">
                Get into Karting
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/TrackTitan">
                Track Titan
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/Contact">Contact</Link>
            </li>
          </ul>
          <div class="d-none d-xl-block">
          <Buymeacoffeebutton />
          </div>
        </div>
      </div>
      </nav>   
    </section>
  )
}

export default Navbar