import React from 'react'

function Footer() {
  return (
    <section id="footer">
        <div class="row g-0 bg-black">
            <div class="col align-self-center text-center footer-text pt-2 bg-dark">
                <h2>© Copyright 2023 Racing Master 45. All Rights Reserved</h2>
            </div>
        </div>
    </section>
  )
}

export default Footer