import React from 'react'
import { FaYoutube } from "react-icons/fa";

function Youtubebutton() {
  return (
    <div class="px-1">
        <a 
        href="https://www.youtube.com/@racingmaster45" 
        target="_blank" 
        rel="noopener noreferrer"
        className="yt-button">
        <FaYoutube />  Watch my channel
        </a>
    </div>
  )
}

export default Youtubebutton