import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

function ContactForm() {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs.send(
      process.env.REACT_APP_YOUR_SERVICE_ID,
      process.env.REACT_APP_YOUR_TEMPLATE_ID,
      formData,
      process.env.REACT_APP_YOUR_PUBLIC_KEY
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Email sent successfully!');
        setFormData({ user_name: '', user_email: '', message: '' }); // Clear form after successful submission
      })
      .catch((err) => {
        console.log('FAILED...', err);
        alert('There was an error sending your email. Please try again later.');
      });
  };

  return (
    <section id="contact" className="bg-contact">
      <div className="row pt-5">
        <div className="col-12 text-center pt-5">
          <p className="contact-heading text-center">
            Fill out the form to get in touch.
          </p>
        </div>
      </div>
      <div className="contact-form-wrapper d-flex justify-content-center projects-text">
        <form id="contact-form" className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            className="form-control rounded border-white mb-3 form-input"
            id="user_name"
            name="user_name"
            placeholder="Name"
            required
            value={formData.user_name}
            onChange={handleChange}
          />
          <input
            type="email"
            className="form-control rounded border-white mb-3 form-input"
            id="user_email"
            name="user_email"
            placeholder="Email"
            required
            value={formData.user_email}
            onChange={handleChange}
          />
          <textarea
            id="message"
            className="form-control rounded border-white mb-3 form-text-area"
            name="message"
            rows="5"
            cols="30"
            placeholder="Message"
            required
            value={formData.message}
            onChange={handleChange}
          />
          <button type="submit" className="submit-btn">
            Send
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;