import React from 'react'

function Buymeacoffeebutton() {
  return (
    <div className="px-1">
        <a href="https://www.buymeacoffee.com/RacingMaster45" target="_blank" rel="noopener noreferrer">
            <img
                className="coffee-button align-self-center"
                alt="Buy me a coffee widget"
                src={require("../assets/images/bmc-button.png")} />
        </a>
    </div>
  )
}

export default Buymeacoffeebutton