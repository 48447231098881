import React from 'react'

function Tracktitan() {
  return (
    <section id="tracktitan" class="bg-tracktitan tracktitan-info">
        <div class="row g-0 tracktitan-text">
            <div class="col-12 text-center">
              <img
                className="tracktitan-title"
                alt="Track Titan page title in company font"
                src={require("../assets/images/tracktitantitle.png")} />
            </div>
            <div class="col-12 col-xl-5 offset-xl-1">
              <h4><strong>Get faster at sim racing!</strong></h4>
              <br></br>
              <p>I'm a massive Track Titan fan and use it a lot to improve my sim racing times.  It contains a number of tools which allow you to compare and improve your lap times on a number of different games.  I have put together a video tutorial which runs through the main features and benefits.</p>
              <br></br>
              <p>Sign up <a href="https://app.tracktitan.io/register?referralCode=RM45TT" target="_blank" rel="noopener noreferrer">here</a> to receive <strong>30 days free plus membership</strong> and <strong>30% off paid memberships and subscriptions</strong> or use code <strong>RM45TT</strong> when you sign up.</p>
              <br></br>
              <p><i>‘If you take advantage of this offer and sign up using my affiliate link, not only will you receive free membership, discounts and rewards but so will I! This all goes towards helping me grow my channel and build my racing career.'</i></p>
            </div>
            <div class="col-12 col-xl-6 text-center mt-5">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/fTEG_EMeErk?si=5XfXXAq9Zg1JGJaq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </section>
  )
}

export default Tracktitan