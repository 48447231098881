import React from 'react'
import { Link } from 'react-router-dom'

function About() {
  return (
    <section id="about" className="bg-about pb-5">
        <div className="row g-0 py-1 py-md-5 px-3 d-flex align-items-center">
            <div className="col-12 col-md-8 text-center">
                <div className='d-md-none col-12 text-center'>
                    <img
                        className="about-pic"
                        alt="racing master 45"
                        src={require("../assets/images/profile.jpg")}
                    />
                </div>
                <h1 className="about-title">
                    I am Racing Master 45
                </h1>
                <p className="col-12 col-md-8 offset-md-2 p-2 py-4 about-text">
                I love motorsport and sim racing! I drive indoor and outdoor rental karts competitively. In addition to this, I volunteer as a motorsports marshal once a month, working in the assembly area, which is an awesome way to get involved and see some of the cars up close.  I've been sim racing since 2020 and started racing in a competitive league in 2023. It is fun and a great tool for improving my real life racing.
                </p>
                <div className='row'>
                    <div className='col d-flex justify-content-center'>
                        <div>
                            <Link
                            to="/Contact"
                            className="wwm-button">
                            <strong>Work With Me</strong>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-none d-md-inline-block col-4 text-center'>
                <img
                    className="about-pic"
                    alt="racing master 45"
                    src={require("../assets/images/profile.jpg")}
                />
            </div>
        </div>
    </section>
  )
}

export default About